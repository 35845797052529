window.bm = window.bm || {};

(function() {

  /**
  * bm.stashFancyboxContent is a helper that can be called inside of
  * fancybox's onCleanup method to keep components from breaking.
  *
  * This moves content from the fancybox into a 1x1 div 'stash' when the fancybox
  * is closed. When the fancybox is triggered again, the content is moved
  * from the div stash back into the fancybox. This ensures that all
  * instantiated components (ie. jPlayers) maintain their functionality.
  **/

  bm.stashFancyboxContent = function(fbConfig, interactionEl) {
    var interaction, popupContents, stash;

    // save the content on cleanup to preserve jPlayer instances
    popupContents = jQuery('#fancybox-content div:first').children();
    stash         = jQuery(interactionEl).find('.stash');

    // if the stash doesn't exist, create it...
    if ( stash.length === 0 ) {
      // Stash is a 1x1 visible div. It needs to remain visible. In some
      // cases making jPlayers invisible caused them to be garbage collected.
      var stash = jQuery('<div>').addClass('stash')
                                 .css({
                                   'left':     '-9999px',
                                   'width':    '1px',
                                   'height':   '1px',
                                   'overflow': 'hidden',
                                   'position': 'absolute'
                                 });

      jQuery(stash).appendTo(interactionEl);
    }
    // make sure to pause jPlayers if the jPlayer library is loaded
    if (jQuery.jPlayer) jQuery.jPlayer.pause();

    // replace the fancybox content with a reference to the stuff we just stashed
    fbConfig.content = jQuery(popupContents).appendTo(stash);
  };

})();
