(function (window) {

  var WORKER_PATH = '/front_end/scripts/recorder/recorderWorker.js',
    Recorder;

  Recorder = function (stream) {
    var recording = false, cbf, timeStart,
      currCallback, cbContext, audioInput,
      gain, gainFunction, processorFunction, worker;

    this.stream = stream;

    context = new AudioContext();
    gainFunction = context.createGain || context.createGainNode;
    gain = gainFunction.call(context);
    audioInput = context.createMediaStreamSource(stream);
    console.log('Media stream created.');
    console.log('input sample rate ' + context.sampleRate);

    audioInput.connect(gain);
    console.log('Input connected to audio context destination.');

    processorFunction = context.createScriptProcessor || context.createJavaScriptNode;
    // Second property set to '0' to let browser decide on buffer size.
    window.recorderProcessor = processorFunction.call(context, 0, 2, 2);

    console.log('window.recorderProcessor Buffer Size: ' + window.recorderProcessor.bufferSize);

    worker = new Worker(WORKER_PATH);
    worker.postMessage({
      command: 'init',
      sampleRate: context.sampleRate
    });

    window.recorderProcessor.onaudioprocess = function (e) {
      var minSec, minutes, seconds;
      if (!recording) {
        return;
      }
      timePassed = Date.now() - timeStart;

      minutes = Math.floor(timePassed / 60000);
      seconds = ((timePassed % 60000) / 1000).toFixed(0);
      minSec = minutes + ":" + (seconds < 10 ? '0' : '') + seconds;

      if (cbf) {
        cbf.call(null, minSec);
      }
      worker.postMessage({
        command : 'record',
        buffer : e.inputBuffer.getChannelData(0)
      });
    };

    this.record = function (callback) {
      recording = true;
      cbf = callback;
      timeStart = Date.now();
      console.log('Recording...');
      return true;
    };

    this.stop = function () {
      recording = false;
      console.log('Recording Stopped.');
      return true;
    };

    this.exportMP3 = function (cb, context) {
      currCallback = cb;
      cbContext = context;
      if (!currCallback) {
        throw new Error('Callback not set!');
      }
      worker.postMessage({ command: 'exportMP3' });
    };

    this.destroy = function () {
      if (this.stream) {
        this.stream.stop();
      }
      context.close();
      delete context;
      delete window.recorderProcessor;
    };

    worker.onmessage = function (e) {
      var blob = e.data,
        mp3Name = encodeURIComponent('audio_recording_' + new Date().getTime() + '.mp3');
      currCallback(blob, mp3Name, cbContext);
    };

    gain.connect(window.recorderProcessor);
    window.recorderProcessor.connect(context.destination);
  };

  window.Recorder = Recorder;

})(window);
